import './MutationObserverSafe-7a5dacdc.js';
import './element-d46853d9.js';
import { _ as _ResizeObserver } from './ResizeObserver-d362a94e.js';

function OnElementResize(opts) {
  return (proto, methodName) => {
    const { componentDidLoad, disconnectedCallback, connectedCallback } = proto;
    const observerAttrName = `${methodName}-resize-observer`;
    const observe = (self, { target }) => {
      var _a;
      const el = target(self);
      if (el) {
        const method = self[methodName];
        (_a = self[observerAttrName]) === null || _a === void 0 ? void 0 : _a.disconnect();
        self[observerAttrName] = _ResizeObserver((entries, _observer) => {
          for (const entry of entries) {
            if (entry.contentBoxSize) {
              // Firefox implements `contentBoxSize` as a single content rect, rather than an array
              const contentBoxSize = Array.isArray(entry.contentBoxSize)
                ? entry.contentBoxSize[0]
                : entry.contentBoxSize;
              method === null || method === void 0 ? void 0 : method.call(self, contentBoxSize.inlineSize);
            }
            else {
              method === null || method === void 0 ? void 0 : method.call(self, entry.contentRect.width);
            }
          }
        });
        self[observerAttrName].observe(el);
      }
    };
    proto.componentDidLoad = function () {
      observe(this, opts);
      return componentDidLoad === null || componentDidLoad === void 0 ? void 0 : componentDidLoad.call(this);
    };
    proto.connectedCallback = function () {
      observe(this, opts);
      return connectedCallback === null || connectedCallback === void 0 ? void 0 : connectedCallback.call(this);
    };
    proto.disconnectedCallback = function () {
      var _a;
      (_a = this[observerAttrName]) === null || _a === void 0 ? void 0 : _a.disconnect();
      return disconnectedCallback === null || disconnectedCallback === void 0 ? void 0 : disconnectedCallback.call(this);
    };
  };
}

export { OnElementResize as O };
